.App{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #121212;
font-family: 'Maven Pro', sans-serif;

}

.main-wrapper{
  height: 100%;
  width: 80%;
  flex-direction: column;
  position: relative;
  background-color: rgb(83, 83, 83);
}

.sign-in-button {
  width: 100%;
  height: 20%;
  font-size: 30px;
  position: relative;
  top: 40%;
  bottom: 40%;
  border: none;
  color: white;
  cursor: pointer;
  background-color: #1976d2;
}

.sign-in-button:focus, .sign-in-button:active{
  outline: none;
}
.header{

  width: 100%;
  height: 10%;
  background-color: #3f3f3f;
  display: flex;
  position: relative;
} 

.header-text{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.logout-button{
  margin-left: auto;
  height: 100%;
  width: 20%;
  background-color: #1976d2;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  cursor: pointer;
  border: none;
  display: block;
}

.logout-button:hover{
  background-position: right center; 
  color: #fff;
  text-decoration: none;
}
.room-navbar{
  position: relative;
  left: 0px;
  display: flex;
  top: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
  background-color: #3f3f3f;
  border-radius: 10px;
}

.room-id{
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #616161;
  color: white;
  margin-bottom: 2px;
  border-radius: 10px;
  transition-duration: .4s;
  cursor: pointer;
}

.room-id:hover{
  filter: brightness(120%);
}

.room-wrapper{
  height: 90%;
  position: absolute;
  top: 10%;
  width: 100%;
  display: flex;
}

.room-options{
  position: absolute;
  bottom: 0px;
}

.room-container{
  width: 100%;
  height: 100%;
  position: relative;
}

.chat-wrapper{
  width: 100%;
  height: 100%;
}

.chat-main{
  width: 70%;
  height: 100%;
}

.message-input{
  width: 80%;
  height: 96%;
  background-color: #3f3f3f;
  border: none;
  color: white;
}

.message-input::placeholder{
  color: gray;

}

.message-input:focus, .message-input:active{
  outline: none;
}

::placeholder{
  color: white;
}

.input-form{
  width: 100%;
  height: 8%;
  position: absolute;
  bottom: 0;
  display: flex;
}

.send-msg{
  width: 20%;
  height: 100%;
  border: none;
}


.send-msg {
   padding: 15px 45px;
   justify-content: center;
   align-items: center;
   display: flex;
   text-align: center;
   text-transform: uppercase;
   color: white;            
   background-color: #1976d2;
  cursor: pointer;
   display: block;
 }

 .send-msg:hover {
   text-decoration: none;
 }

 .send-msg:active, .send-msg:focus{
   outline: none; 
 }

.messages{
  overflow: auto;
  height: 92%;
  position: relative;
}


img{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.message{
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  position: relative;
  min-height: 5%;
  width: 50%;
  transition-duration: .4s;
}

.message::placeholder{
  color: gray;

}

.message.send{
  flex-direction: row-reverse;
  margin-top: 20px;
  background-color: #616161;
  margin-left: auto;
  color: white;
}

.message.received{
  margin-top: 20px;
  background-color: #616161;
  color: white;
  margin-right: auto;
}

.message>div{
  height: 100%;
  width: 80%;
  word-break:break-all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-placeholder{
  font-weight: 400;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: whitesmoke;
}

.createRoomButton{
  background-color: #1976d2;

  height: 100%;
  border: none;
  color: white;
  cursor: pointer;
  width: 15%;
  position: absolute;
}

.createRoomButton{
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
}

.createRoomButton:hover{
  background-position: right center; 
  color: #fff;
  text-decoration: none;
}

.createRoomButton:active, .createRoomButton:focus{
  outline: none;
}

.createRoomInput::placeholder{
  color: gray;
  font-size: 12px;
}
.createRoomForm{
  width: 50%;
  height: 100%;
}

.createRoomInput{
  height: 96%;
  font-size: 30px;
  width: 90%;
  border: none;
  background-color: #616161;
  color: white;

}

.createRoomInput:focus, .createRoomInput:active{
  outline: none;
}

.create-wrapper{
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.join-form{
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 8%;
  display: flex;

}

.join-input{
  width: 80%;
  border: none;
  background-color: #616161;
  color: white;
}

.join-input::placeholder{
  color: gray;
  font-size: 12px;
}

.join-input:active, .join-input:focus{
  outline: none;
}

.join-button{
  height: 100%;
  width: 20%;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  color: white;    
  background-color: #1976d2;
  border: none;
  cursor: pointer;
}

.nav{
  display: none;
}

@media only screen and (max-width: 600px){
  .main-wrapper{
    width: 100%;
    height: 100%;
  }
  .room-navbar {
    display: none;
  }
  .chat-main{
    width: 100%;
  }
  .header{
    height: 8%;
    display: flex;
    align-items: center;
  }
  .send-msg{
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .createRoomButton{
    height: 50%;
    margin-top: 16px;
  }
  .createRoomInput{
    height: 46%;
    width:50%;
    margin-left: 10px;
    margin-top: 16px;
    font-size: 12px;

  }
  .logout-button{
    height: 50%;
  }
  .createRoomForm{
    width: 100%;

  }
  .createRoomInput::placeholder{
    opacity: 0;
  } 
  .header-text{
    display: none
  }
  button{
    font-size: 12px;
  }
  .nav{
    display: inline;
  }
  .room-mobile{
  position: absolute;    
  width: 100%;
  height: 100%;
  background-color: #3f3f3f;
  z-index: 100;
  }
} 